<script setup lang="ts" generic="T extends {id: string}">
const props = defineProps<{
	suggestions: T[];
}>();

const emit = defineEmits<{
	(e: "search", query: string, finishLoading: () => void): void;
	(e: "select", selection: T, updateQuery: (query: string) => void): string;
}>();

const searchInput = ref<{ inputRef: HTMLInputElement }>();
const query = ref("");
const isSearching = ref(false);
const searchResults = toRef(props, "suggestions");
const selectionIndex = ref(-1);
const hasFocus = ref(false);

const onQueryChange = async () => {
	hasFocus.value = true;
	selectionIndex.value = -1;
	isSearching.value = true;
	emit("search", query.value, () => {
		isSearching.value = false;
	});
};

const onSearchKeydown = (event: KeyboardEvent) => {
	if (event.key === "ArrowDown") {
		event.preventDefault();
		selectionIndex.value = Math.min(selectionIndex.value + 1, searchResults.value.length - 1);
	} else if (event.key === "ArrowUp") {
		event.preventDefault();
		selectionIndex.value = Math.max(selectionIndex.value - 1, 0);
	} else if (event.key === "Enter") {
		event.preventDefault();
		onSelect(searchResults.value[selectionIndex.value] as T);
	}
};

const onSelect = (selection?: T) => {
	if (!selection) return;

	searchInput.value?.inputRef.blur();
	selectionIndex.value = -1;
	query.value = emit("select", selection, (newValue: string) => (query.value = newValue));
};

watch(searchResults, (results) => {
	if (results.length > 0) {
		selectionIndex.value = 0;
	}
});
</script>

<template>
	<div class="relative">
		<KippieInput
			v-bind="$attrs"
			v-model="query"
			@input="onQueryChange"
			ref="searchInput"
			autocomplete="off"
			data-1p-ignore
			@focus="hasFocus = true"
			@blur="hasFocus = false"
			@keydown.stop="onSearchKeydown"
		>
			<template v-for="(_, name) in $slots" #[name]="slotData">
				<slot :name="name" v-bind="slotData" />
			</template>
		</KippieInput>
		<div
			class="rounded-lg border border-gray-200 overflow-hidden shadow-lg mt-2 absolute w-full bg-white z-20"
			v-if="hasFocus"
		>
			<div class="text-xs text-gray text-center py-2 px-4" v-if="isSearching">Resultaten zoeken...</div>
			<div class="text-xs text-gray text-center py-2 px-4" v-else-if="!searchResults.length && !!query">
				Geen resultaten gevonden voor '{{ query }}'
			</div>
			<div class="text-xs text-gray text-center py-2 px-4" v-else-if="!searchResults.length && !query">
				Voer een plaatsnaam of postcode in om te zoeken
			</div>
			<div
				v-else
				class="px-4 py-2 border-b border-gray-200 last-of-type:border-b-0 text-xs hover:bg-gray-100 transition-colors duration-100 cursor-pointer"
				v-for="(result, index) in searchResults"
				:class="{
					'bg-gray-100': index === selectionIndex
				}"
				:key="result.id"
				@mousedown="onSelect(result as T)"
			>
				<slot name="result" :result="result" />
			</div>
		</div>
	</div>
</template>
